import React from 'react';
import localize from 'localization';
import { InputText, SwitchButton } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import classnames from 'classnames';

const { translate: t } = localize;

export default class InboundLivetexEditForm extends BaseEditForm {
  channelId = '';
  integrationToken = '';
  greetingMessage = '';

  constructor(props) {
    super(props);
    this.form = {};
    let forceReply = false;

    if (props.editableChannel.customData.hasOwnProperty('forceReply')) {
      forceReply = props.editableChannel.customData['forceReply'];
    }

    this.state = {
      localErrors: {},
      forceReply,
    };
  }

  validate = () => {
    const fields = {
      channelId: this.channelId.value,
      integrationToken: this.integrationToken.value,
      greetingMessage: this.greetingMessage.value,
    };
    if (this.props.editableChannel.id) {
      fields['accessToken'] = this.form.accessToken.value;
    }
    const localErrors = Object.keys(fields).reduce((localErrors, fieldKey) => {
      if (!fields[fieldKey]) localErrors[fieldKey] = true;
      return localErrors;
    }, {});
    return localErrors;
  };

  submit = async e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fetching: true });

    const localErrors = this.validate();

    this.setState({ localErrors });
    if (Object.values(localErrors).includes(true)) {
      this.setState({ fetching: false });
      return;
    }

    if (Boolean(this.props.editableChannel.id)) {
      await this.saveChannel();
    } else {
      await this.createChannel();
    }

    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel, editableChannel } = this.props;

    const channelData = {
      ...editableChannel,
      channelType: this.props.editableChannel.channelType,
      botName: this.form.name.value,
      senderName: this.form.name.value,
      config: {},
      customData: {
        channelId: this.channelId.value,
        integrationToken: this.integrationToken.value,
        greetingMessage: this.greetingMessage.value,
        forceReply: this.state.forceReply,
      },
    };

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel, editableChannel } = this.props;

    const channelData = {
      ...editableChannel,
      botName: this.form.name.value,
      senderName: this.form.name.value,
      accessToken: this.form.accessToken.value,
      channelType: this.props.editableChannel.channelType,
      customData: {
        channelId: this.channelId.value,
        integrationToken: this.integrationToken.value,
        greetingMessage: this.greetingMessage.value,
        forceReply: this.state.forceReply,
      },
    };

    await editChannel(channelData);
    this.props.onClose();
  };

  checkIsFetching = () => {
    const { fetching } = this.props;
    const { hasError, fetching: localFetching } = this.state;
    return fetching || hasError || localFetching;
  };

  clearError = (value, name, event) => {
    if (this.state.localErrors[event.target.id]) {
      const newLocalErrors = { ...this.state.localErrors, [event.target.id]: false };
      this.setState({ localErrors: newLocalErrors });
    }
  };

  toggleForceReply = value => this.setState({ forceReply: value });

  renderInputs() {
    const { editableChannel } = this.props;
    const { localErrors, forceReply } = this.state;

    return (
      <>
        {editableChannel.id && (
          <div className={classes.formControl}>
            <p className={classnames(classes.label, classes.labelRequired)}>{t('Bot token')}</p>
            <InputText
              innerRef={input => (this.form.accessToken = input)}
              errorText={localErrors['accessToken']}
              onChange={this.clearError}
              id='accessToken'
              defaultValue={editableChannel.accessToken}
              placeholder={t('Enter custom', t('Bitrix24 bot name').toLowerCase())}
              withEvent
            />
          </div>
        )}
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('Integration Token')}</p>
          <InputText
            innerRef={input => (this.integrationToken = input)}
            id='integrationToken'
            errorText={localErrors['integrationToken']}
            onChange={this.clearError}
            defaultValue={editableChannel.customData.integrationToken}
            placeholder={t('Enter custom', t('Integration Token').toLowerCase())}
            withEvent
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('Channel ID')}</p>
          <InputText
            innerRef={input => (this.channelId = input)}
            id='channelId'
            errorText={localErrors['channelId']}
            onChange={this.clearError}
            defaultValue={editableChannel.customData.channelId}
            placeholder={t('Enter custom', t('Channel ID').toLowerCase())}
            withEvent
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('Greeting Message')}</p>
          <InputText
            innerRef={input => (this.greetingMessage = input)}
            id='greetingMessage'
            errorText={localErrors['greetingMessage']}
            onChange={this.clearError}
            defaultValue={editableChannel.customData.greetingMessage}
            placeholder={t('Enter custom', t('Greeting Message').toLowerCase())}
            withEvent
          />
        </div>

        <div className={classnames(classes.formControl, classes.switchContainer)}>
          <SwitchButton id='forceReply' value={forceReply} color='primary' onChange={this.toggleForceReply} />
          <label htmlFor='forceReply'>{t('ChannelsConfig: force reply jivo label')}</label>
        </div>
      </>
    );
  }
}
