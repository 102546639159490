var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProjectsApi as BotProjectsApi, ProjectsPublicApi, } from "../../generated/Botadmin";
export class ProjectService {
    constructor(accountId, axios) {
        this.getFormattedProject = (project) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return Object.assign(Object.assign({}, project), { repositoryUrl: (_a = project.repository) === null || _a === void 0 ? void 0 : _a.url, repositoryLogin: (_b = project.repository) === null || _b === void 0 ? void 0 : _b.login, repositoryAuthType: (_c = project.repository) === null || _c === void 0 ? void 0 : _c.authType, justAiKey: (_d = project.repository) === null || _d === void 0 ? void 0 : _d.justAiKey, cleanupDialogs: (_e = project.cleanup) === null || _e === void 0 ? void 0 : _e.dialogs, cleanupOlderThenDays: (_f = project.cleanup) === null || _f === void 0 ? void 0 : _f.olderThenDays, jaicfProject: (_g = project.jaicf) === null || _g === void 0 ? void 0 : _g.isJaicfProject, webHookUrl: (_h = project.jaicf) === null || _h === void 0 ? void 0 : _h.webHookUrl, externalBotToken: (_j = project.jaicf) === null || _j === void 0 ? void 0 : _j.externalBotToken, botType: project.botChannelsType, lastModificationData: project.lastModificationTime });
        };
        this.getProjectsWithPagination = (_a) => __awaiter(this, [_a], void 0, function* ({ product, page, size, name, channelTypeList, sortingField, withoutChannels, productTypeList, tagLabelList, projectShortName, }) {
            var _b;
            const rawData = yield this.projectsPublicApi.getAllProjects(product, page, size, name, channelTypeList, sortingField, withoutChannels, productTypeList, tagLabelList, projectShortName);
            const formattedProjects = (_b = rawData.data.projects) === null || _b === void 0 ? void 0 : _b.map((project) => this.getFormattedProject(project));
            return Object.assign(Object.assign({}, rawData), { data: Object.assign(Object.assign({}, rawData.data), { projects: formattedProjects }) });
        });
        this.getProjectByProjectId = (id) => {
            return this.projectsPublicApi.getProjectByProjectId(id);
        };
        this.getByProjectShortName = (shortname) => __awaiter(this, void 0, void 0, function* () {
            const rawData = yield this.projectsPublicApi.getByProjectShortName(shortname);
            return Object.assign(Object.assign({}, rawData), { data: this.getFormattedProject(rawData.data) });
        });
        this.getChannelsConnectedProjects = () => {
            return this.projectsPublicApi.getAllChannelTypes();
        };
        this.getAllProjectTypes = () => {
            return this.projectsPublicApi.getAllProjectTypes();
        };
        this.getAllProjectTags = () => {
            return this.projectsPublicApi.getAllProjectTags();
        };
        this.getProjects = (options) => {
            return this.projectsApi.readAll(this.accountId, undefined, options);
        };
        this.createProject = (botProjectCreateDto, options) => {
            return this.projectsApi
                .create(this.accountId, botProjectCreateDto, undefined, options)
                .then((res) => res.data);
        };
        this.updateProject = (projectId, botProjectCreateDto, options) => {
            return this.projectsApi.update(this.accountId, projectId, botProjectCreateDto, options);
        };
        this.cloneProject = (projectShortName, newName, options) => {
            if (!newName)
                newName = projectShortName + "-clone";
            return this.projectsApi.clone(this.accountId, projectShortName, newName, true, options);
        };
        this.accountId = accountId;
        this.projectsApi = new BotProjectsApi({}, ProjectService.BASE_PATH, axios);
        this.projectsPublicApi = new ProjectsPublicApi({}, ProjectService.BASE_PATH, axios);
    }
}
ProjectService.BASE_PATH = "";
